import React from 'react'
import store from '../images/icons/store-light.svg'	

const GreenBlockCta = ({ page, title, desc, btn }) => {
    return (
        <div className={`greenBlockCta ${page}`}>
			<div className="container">
				<div className="block-got-commerce">
					<div className="icon">
						<img src={store} alt=""/>
					</div>
					<div className="content">
						<h3 className="text-white">{title}</h3>
						<p className="text-white">{desc}</p>
					</div>
					<div className="cta">
						<button className="btn-primary-inverted" onClick={btn[1]}>{btn[0]}</button>
					</div>
				</div>
			</div>
		</div>
    )
}

export default GreenBlockCta
