import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero';
import MainBlock from '../components/main-block';
import LkpCard from '../components/LkpCard';
import GreenBlockCta from '../components/greenBlockCta';
import DualBlock from '../components/dualBlock';
import TransactionQuery from '../components/query';
import ModuleConsumos from '../components/moduleConsumos';

import start1 from '../images/step1.png'
import start2 from '../images/step2.png'
import start3 from '../images/step3.png'
import start4 from '../images/step4.png'
import start5 from '../images/step5.png'

import cardBlank from '../images/icons/credit-card-blank-light.svg'
import slider from '../images/icons/sliders-h-square-light.svg'
import marker from '../images/icons/map-marker-alt-light.svg'
import mobile from '../images/icons/mobile-light.svg'
import chart from '../images/icons/chart-line-light.svg'
import bag from '../images/icons/shopping-bag-light.svg'

import qr from '../images/qr.jpg'

import { injectIntl } from 'gatsby-plugin-intl';

const EmpresasPage = ({ intl }) => (
	<Layout page={'empresas'}>
		<SEO title="Home" />
		<Hero page={'empresas'} h1={intl.formatMessage({ id: "empresas.hero.title" })} />
		<ModuleConsumos location="empresas" />

		<MainBlock page={'empresas'} />

		<div className="start-now">
			<div className="container">
				<div className="heading">
					<h2 className="text-center">{intl.formatMessage({ id: "empresas.function.title" })}</h2>
				</div>
				<div className="items">
					<div className="item text-center">
						<img src={start1} alt="" />
						<h4>{intl.formatMessage({ id: "empresas.function.blocks.b1.title" })}</h4>
						<p>{intl.formatMessage({ id: "empresas.function.blocks.b1.desc" })}</p>
					</div>
					<div className="item text-center">
						<img src={start2} alt="" />
						<h4>{intl.formatMessage({ id: "empresas.function.blocks.b2.title" })}</h4>
						<p>{intl.formatMessage({ id: "empresas.function.blocks.b2.desc" })}</p>
					</div>
					<div className="item text-center">
						<img src={start3} alt="" />
						<h4>{intl.formatMessage({ id: "empresas.function.blocks.b3.title" })}</h4>
						<p>{intl.formatMessage({ id: "empresas.function.blocks.b3.desc" })}</p>
					</div>
					<div className="item text-center">
						<img src={start4} alt="" />
						<h4>{intl.formatMessage({ id: "empresas.function.blocks.b4.title" })}</h4>
						<p>{intl.formatMessage({ id: "empresas.function.blocks.b4.desc" })}</p>
					</div>
					<div className="item text-center">
						<img src={start5} alt="" />
						<h4>{intl.formatMessage({ id: "empresas.function.blocks.b5.title" })}</h4>
						<p>{intl.formatMessage({ id: "empresas.function.blocks.b5.desc" })}</p>
					</div>
				</div>
			</div>
		</div>

		<div className="beneficios-individuos">
			<div className="container">
				<div className="beneficios-heading">
					<h2 className="text-center">{intl.formatMessage({ id: "empresas.benefits.title" })}</h2>
				</div>
				<div className="beneficios-grid">
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={cardBlank} alt="" /></div>
						<div className="content">
							<h4>{intl.formatMessage({ id: "empresas.benefits.blocks.b1.title" })}</h4>
							<p>{intl.formatMessage({ id: "empresas.benefits.blocks.b1.desc" })}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={slider} alt="" /></div>
						<div className="content">
							<h4>{intl.formatMessage({ id: "empresas.benefits.blocks.b2.title" })}</h4>
							<p>{intl.formatMessage({ id: "empresas.benefits.blocks.b2.desc" })}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={marker} alt="" /></div>
						<div className="content">
							<h4>{intl.formatMessage({ id: "empresas.benefits.blocks.b3.title" })}</h4>
							<p>{intl.formatMessage({ id: "empresas.benefits.blocks.b3.desc" })}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={mobile} alt="" /></div>
						<div className="content">
							<h4>{intl.formatMessage({ id: "empresas.benefits.blocks.b4.title" })}</h4>
							<p>{intl.formatMessage({ id: "empresas.benefits.blocks.b4.desc" })}</p>
						</div>
					</LkpCard>
				</div>
			</div>
		</div>
		<div className="container">

		</div>
		<div className="empresas-how-it-works-top"></div>
		<div className="empresas-how-it-works">
			<div className="container">
				<div className="beneficios-heading">
					<h2 className="text-center text-white m-t-100">{intl.formatMessage({ id: "empresas.advantages.title" })}</h2>
				</div>
				<div className="index-features-grid">
					<div className="flex-gap">
						<div className="feature-item">
							<div className="icon"><img src={chart} height="30" alt="" /></div>
							<h4 className="text-white">{intl.formatMessage({ id: "empresas.advantages.i1.title" })}</h4>
							<p className="text-white">{intl.formatMessage({ id: "empresas.advantages.i1.desc" })}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={slider} height="30" alt="" /></div>
							<h4 className="text-white">{intl.formatMessage({ id: "empresas.advantages.i2.title" })}</h4>
							<p className="text-white">{intl.formatMessage({ id: "empresas.advantages.i2.desc" })}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={bag} height="30" alt="" /></div>
							<h4 className="text-white">{intl.formatMessage({ id: "empresas.advantages.i3.title" })}</h4>
							<p className="text-white">{intl.formatMessage({ id: "empresas.advantages.i3.desc" })}</p>
						</div>
					</div>
				</div>
				<div className="qr-block">
					<img src={qr} alt="" />
				</div>
				<div className="beneficios-heading">
					<h2 className="text-center text-white m-t-50">{intl.formatMessage({ id: "empresas.advantages.extension.title" })}</h2>
					<p className="text-white text-center">{intl.formatMessage({ id: "empresas.advantages.extension.desc" })}</p>
				</div>
			</div>
		</div>

		<div className="empresas-how-it-works-bottom"></div>

		<DualBlock page="empresas" />

		<TransactionQuery page="empresas" />

		<GreenBlockCta
			page={"empresas"}
			title={intl.formatMessage({ id: "empresas.green-cta.title" })}
			desc={intl.formatMessage({ id: "empresas.green-cta.desc" })}
			btn={[intl.formatMessage({ id: "empresas.green-cta.cta" }), () => window.location.replace('/users/signups/new')]}
		/>

	</Layout>
)

export default injectIntl(EmpresasPage)
